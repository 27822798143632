import { useTranslation } from 'src/i18n/useTranslation';
import React from 'react';
import styled from 'styled-components';

export default function BuyFooter({
  space
}: {
  space?: string
}){
  const { t } = useTranslation();
  return <Footer>
    <p>{t("chronos.mobile.settings.byFooter.confirmInfo")}</p>
    <p style={{marginTop: space}}>{t("chronos.app.sidebar.support")}  •  {t("chronos.mobile.settings.byFooter.conditions")}  •  {t("chronos.auth.pageContainer.confidentiality")}</p>
  </Footer>
}

const Footer = styled.div`
  color: var(--text-third);
  margin-top: 1rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
`