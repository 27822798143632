import React, { useState } from 'react';
import { setProfile as setProfileLS } from 'src/store/localStorage/profile';
import theme from 'src/theme';
import api, { ApiError, IAuth } from 'src/api';
import MainLoader from "./loading";
import { changeLanguage } from 'src/i18n/useTranslation';
import { Themes } from 'src/theme';
import i18n from 'src/i18n/i18n';
import RegionSelectModal from 'src/components/RegionSelectModal';
import { getURL, initialAuth } from 'src/utils';

const languages = ['ru', 'en', 'es', 'pt'];
const getLocalLanguage = () => {
  const browserLanguageRegion = window.navigator.language;
  const browserLanguage = browserLanguageRegion.substring(0, 2).toLowerCase();
  if(languages.includes(browserLanguage)) {
    return browserLanguage
  } else {
    return browserLanguage;
  }
}

const TOKEN_LS = 'token'

export default function MainAuth() {

  const [refreshOk, setRefreshOk] = useState<boolean>(false);
  const [showRegionModal, setShowRegionModal] = useState<boolean>(false);
  const [localLng, setLocalLng] = React.useState(false);
  const [isInit, setIsInit] = React.useState(false);

  const shareMapId = window.location.pathname.includes('workspace') ? window.location.pathname.split('/').slice(-1)[0] : undefined;

  React.useEffect(() => {
    if (localLng) {
      changeLanguage(getLocalLanguage());
    }
  }, [localLng]);

  React.useEffect(() => {
    const URL = getURL();
    const sp = URL.searchParams;
    const isShareMap = sp.has('share');

    const init = async () => {
      let auth: IAuth | null = null;

      // авторизация
      if (sp.has(TOKEN_LS)) {

        try {
          auth = await api.signIn(sp.get(TOKEN_LS) ?? ''); // DISPATCH
          api.token = auth.token;
        } catch (err) {
          const apiError = (err as ApiError);
          if(apiError.message.toUpperCase() === 'BLOCKED') {
            alert('Слишком много обращений, попробуйте через 5 минут')
          } else if(!apiError.skipGoToAuth) {
            api.goToAuth();
          }
        } finally {
          sp.delete(TOKEN_LS);
          window.history.pushState({}, '', URL.toString());
        }

      } else {
        try {
          auth = await api.refreshToken(api.token?.refresh ?? '');
          api.token = auth.token;
        } catch (err) {
          if (isShareMap) {
            auth = initialAuth;
          } else if (!(err as ApiError).skipGoToAuth) {
            api.goToAuth();
          }
        }
      }

      if (auth) {

        if (!showRegionModal && !!auth.region && !auth.region.id) {
          setLocalLng(true);
          setShowRegionModal(true);
        }


        if (auth.language || isShareMap) {
          await changeLanguage(auth.language || auth.region?.language || 'ru')
          if (i18n.language === auth.language || isShareMap) setIsInit(true);
        } else {
          setIsInit(true);
        };

        // шэйринг страниц
        if (isShareMap && shareMapId) {
          const formId = +(shareMapId ?? '');
          localStorage.setItem('sharedId', `${formId}`)
          const form = await api.form(formId);

          if (form && !form.error) {
            if (auth.id === -1) {
              theme.init(form.theme as Themes)
            }
            auth.id = form.userId;
          } else {
            auth.id = 25712; // не важно
          }
        }

        api.token = auth.token;
        theme.init(auth.theme as Themes);
        setProfileLS(auth);
        setRefreshOk(true);

      }

    };

    init();
  }, []);



  if(showRegionModal) {
    return <RegionSelectModal onClose={() => {
        setShowRegionModal(false);
        setLocalLng(false);
      }
    } />
  };

  return ( refreshOk && isInit ? <MainLoader /> : null );
}
