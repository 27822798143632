import React, { useEffect } from 'react';
import StoreProvider from './store';
import MainAuth from './app/auth';
import { NotificationsProvider } from './store/context/notifications';
import { getDeviceInfo } from './utils';
import { getUTMCommentForCP } from 'src/libs';
import { setUtmLs } from './helpers/localStorage';

const { isMobile, isTablet } = getDeviceInfo();

const App = () => {
  useEffect(() => {
    const urlUTM = getUTMCommentForCP()
    urlUTM && setUtmLs(urlUTM)
  }, [])

  if(
    typeof isMobile === 'boolean'
    && typeof isTablet === 'boolean'
    && !isMobile || isTablet
  ) {
    window.location.href = process.env.REACT_APP_PROCESSOR_APP_URL!;
  }

  return (
    <StoreProvider>
      <NotificationsProvider>
        <MainAuth />
      </NotificationsProvider>

			<div id="appPopups"></div>
    </StoreProvider>
  );
}

export default App;
