const PROFILE_LS_NAME = 'mobile_profile_v2.4';

export const getProfile = () => {
  const item = localStorage.getItem(PROFILE_LS_NAME);
  return item && JSON.parse(item);
}

export const setProfile = (data: any) => {
  localStorage.setItem(PROFILE_LS_NAME, JSON.stringify(data));
}

export const unsetProfile = () => {
  localStorage.removeItem('mobile_profile_v2.2'); // 25/03/22
  localStorage.removeItem('mobile_profile_v2.3'); // 25/03/22
  localStorage.removeItem(PROFILE_LS_NAME);
}