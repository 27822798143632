import { IRootState } from 'src/store';
import { createSelector } from "reselect";
import { cloneDeep } from 'lodash';
import StarsData from 'src/app/pages/settings/components/stars.json'

export function allSettingsAstro(state: IRootState) {
  return state.settings.astro;
}

const defaultStarList = {
  showWithObjects: true,
  list: [
    'Algol',
    'Aldebaran',
    'Antares',
    'Betelgeuse',
    'Regulus',
    'Sirius'
  ]
}

export function allSettings(state: IRootState) {
  const activeProfileId = state.settings.settings.data.activeProfileId
  state.settings.settings.data.activeProfileId = activeProfileId === -1 ? 0 : activeProfileId
  const _settings = cloneDeep(state.settings.settings);
  return _settings;
}

export function getCustomizations(state: IRootState) {
  return state.settings.customizations;
}

export const getActiveAstroProfile = createSelector(
  allSettingsAstro, allSettings, 
  (astroProfiles, settings) => {

    const horarStars: string[] = StarsData.filter(({ isHorar }) => isHorar).map(({ astroName }) => astroName);
    astroProfiles.data.map((p: any) => {
      if (!p.fixedStars) {
        //MAA
        if (p.id === 1) {
          p.fixedStars = {
            natal: { list: [...horarStars], showWithObjects: true },
            synastry: { list: [...horarStars], showWithObjects: true },
            horar: { list: [...horarStars], showWithObjects: true }
          }
          //PAA
        } else if (p.id === 0) {
          p.fixedStars = {
            natal: { list: [...defaultStarList.list], showWithObjects: true },
            synastry: { list: [...defaultStarList.list], showWithObjects: true },
            horar: { list: [...horarStars], showWithObjects: true }
          }
        }
      }

      if (p.widgets && !p.widgets?.dispositors.planets.find((planet: any) => planet.id === 'Eris')) {
        const _filterdPlanets = p.widgets?.dispositors.planets.filter((planet: any) => planet.id !== 'Eris')
        p.widgets.dispositors.planets = [
          ..._filterdPlanets,
          { id: 'Eris', label: 'astro.eris', isActive: p.id === 1 }
        ]
      } else {
        // const _filterdPlanets = p.widgets?.dispositors.planets.filter((planet: any) => planet.id !== 'Eris')
        // p.widgets.dispositors.planets = [
        //   // ..._filterdPlanets,
        //   { id: "Chiron", label: "astro.chiron", isActive: false },
        //   { id: "Lilith", label: "astro.lilith", isActive: false },
        //   { id: "Nodes", label: "astro.nodes", isActive: false },
        //   { id: "Selena", label: "astro.selena", isActive: false},
        //   { id: 'Eris', label: 'astro.eris', isActive: p.id === 1 }
        // ]
      }

      if (p.showHigherPlanets === undefined && [0, 1].includes(p.id)) {
        p.showHigherPlanets = true;
      }

      if (p.showMinorPlanets === undefined && [0, 1].includes(p.id)) {
        p.showMinorPlanets = true;
      }

      if (!p.hideTransitAspects) {
        // московская академия астрологии
        if (p.id === 1) {
          p.hideTransitAspects = false;
          // школа Павла Андреева
        } else if (p.id === 0) {
          p.hideTransitAspects = true;
        }
      }

      return p;
    });


    let activeAstroProfile = astroProfiles.data.find((item: any) => item.id == settings.data.activeProfileId);
    
    if (!activeAstroProfile) {
      activeAstroProfile = astroProfiles.data.find((item: any) => item.mutable);
    }
    return cloneDeep(activeAstroProfile);
  }
);


export const getCurrentCustomization = createSelector(
  getCustomizations, allSettings,
  (customizations, settings) => {

    const id = settings.data.activeCustomizationId || customizations.data[0].id;

    let customization = customizations.data.find((item: any) => item.id === id);
    return customization || customizations.data[0];
  }
)
