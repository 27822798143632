import React, { useMemo } from 'react';
import styled from 'styled-components';

import { IComputedRadius, ICustomizationData, radialPoint, CircleMode, IObject, IAspect, IPoint, isNotTransits, IFixedStar, IChain } from 'src/libs';

import { calcHorarSigns } from '../Widgets/Horar/horar';

import Objects from './Objects';
import Aspects from './Aspects';
import Zodiacs from './Zodiacs';
import Houses from './Houses';
import SoulObjects from './SoulObjects';

import { IStrongs } from '../Widgets/data';
import { IHighlight } from '../../index';
import { hasExt as hasExtF } from '../Widgets/AspectsTable';

import defaultPersonalization, { defaultComputedRadius } from './settings/personalization';

import { getSettings, getSettingsAstro } from 'src/store/actions/settings';
import { getProfile } from 'src/store/localStorage/profile';
import { useSelector } from 'src/store/utils';
import { allSettings, allSettingsAstro, getActiveAstroProfile } from 'src/store/reducers/settings/selectors';
import { useTranslation } from 'react-i18next';
import FixedStars from './FixedStars';

export function getSectorLength(i: number, values: number[]): number {
  const lon = values[i];

  let nlon = values[(i + 1) % 12];

  if (nlon < lon) { nlon += 360 }

  return nlon - lon;
}

export interface CirclePartProps {
  mode: CircleMode;
  hasExt: boolean;
  radius: number;
  x: number;
  y: number;
  rotation: number;
  highlights?: number[];
  personalization: ICustomizationData;
  computedRadius: IComputedRadius;
  isEditor?: boolean;
  point(r: number, a: number): IPoint;
  onHelp(x: string, i: number): void;
  onHover(x: string, i: number): void;
}

interface CircleProps {
  mode: CircleMode;
  x: number;
  y: number;
  r: number;
  houses: number[];
  objects: IObject[];
  housesExt: number[];
  objectsExt: IObject[];
  aspects: IAspect[];
  strongs: IStrongs;
  fixedStars: IFixedStar[];
  showFixedStars: boolean;
  soulStrongs: number[];
  soulObjects: number[];
  chains: IChain[];
  highlights?: IHighlight[];
  personalization?: ICustomizationData;
  computedRadius?: IComputedRadius;
  isEditor?: boolean;
  onChanged(key: string, value: any): void;
}

export default function Circle(props: CircleProps) {
  const personalization = props.personalization || defaultPersonalization;
  const computedRadius = props.computedRadius || defaultComputedRadius;

  const hasExt = hasExtF(props.mode);

  const objects = props.mode === 'prog_prog' ? props.objectsExt : props.objects;
  const houses = props.mode === 'prog_prog' ? props.housesExt : props.houses;
  const { data: profiles } = useSelector(allSettingsAstro);
  const { data: settings } = useSelector(allSettings);
  const { t } = useTranslation();

  const lsUser = getProfile();
  getSettings(lsUser.id, false)
  const activeAstroProfile = useSelector(getActiveAstroProfile);
  const isNotCustomProfile = [0, 1].includes(activeAstroProfile.id);
  const startPoint = isNotCustomProfile ? 'asc' : activeAstroProfile.startingPoint;
  const rotationDir = isNotCustomProfile ? 'ccw' : activeAstroProfile.rotationDir;
  const showHigherPlanets = activeAstroProfile?.showHigherPlanets;
  const showMinorPlanets = activeAstroProfile?.showMinorPlanets;
  
  let rotation: number;
  if (props.mode === 'horar') {
    rotation = 180 - (houses[0] || 0);
  } else if (props.mode === 'soul') {
    rotation = 180;
  } else {
    if (startPoint === 'aries') {

      if ( rotationDir === 'cw' ) {
        rotation = 0;
      } else {
        rotation = 180;
      }
    
    } else {
      if (rotationDir === 'cw') {
        rotation = 180 - (houses[0] - 180 || 0)
      } else rotation = 180 - ( houses[0] || 0)
    }
  }


  const partProps = {
    mode: props.mode,
    x: props.x,
    y: props.y,
    hasExt,
    radius: props.r,
    point: (r: number, a: number) => {
      if (props.mode === 'horar') {
        return radialPoint(props.x, props.y, r, a + (props.mode === 'horar' ? 0 : rotation))
      } else if (props.mode === 'soul') {
        return radialPoint(props.x, props.y, r, a + (rotation))
      } else {
        if (rotationDir === 'ccw')  {
          return radialPoint(props.x, props.y, r, a + (rotation))
        } else {
          return radialPoint(props.x, props.y, r, -(a + (rotation)))
        }
      }
    
    },
    onHelp: (category: string, id: number) => {
      onHelpNew(category, id);
    },//props.onChanged('description', { category, id }),
    onHover: (what: string, id: number) => {
      /*if (id === -1) {
        props.onChanged('highlights', []);
      } else if (what === 'aspect') {
        props.onChanged('highlights', highlightAspect(props.mode, props.aspects[id], hasExt));
      }*/
    },
    rotation,
    isEditor: props.isEditor,
    personalization,
    computedRadius
  };

  const onHelpNew = (category: string, id: number) => {
    if (category === 'objects' && id >= 10) { return }
    props.onChanged('prompt', { category, id });
  };

  const { radius } = personalization.circle;

  const radiusModified = {
    internalSoul: props.r * (radius.internalSoul || 0.1),
    internalHorar: props.r * radius.internalHorar,
    internal: props.r * (hasExt ? computedRadius.internalExt : computedRadius.internal),
    zodiacsInternal: props.r * (hasExt ? computedRadius.zodiacsInternalExt : computedRadius.zodiacsInternal),
    zodiacsExternal: props.r * (hasExt ? computedRadius.zodiacsExternalExt : computedRadius.zodiacsExternal),
    external: props.r * radius.external
  };

  const getInternalRadius = (mode: CircleMode) => {
    switch (mode) {
      case 'horar': return radiusModified.internalHorar;
      case 'soul': return radiusModified.internalSoul || 0.1;
      default: return radiusModified.internal;
    }
  };

  const getDelimeterRadius = (index: number) => {
    const objectsRadius = radiusModified.zodiacsInternal - (radiusModified.internalSoul || 0.1);
    if (index === 0) {
      return objectsRadius / 8 * index + (radiusModified.internalSoul || 0.1);
    }

    return objectsRadius / 8 * (index + 1) + (radiusModified.internalSoul || 0.1);
  };

  const highlights = (props.highlights?.length || 0) === 0 ? null : props.highlights!;

  const hlAspects = highlights?.filter(h => h.type === 'aspect').map(h => h.id);

  const hlObjects = highlights?.filter(h => h.type === 'object').map(h => h.id);
  const hlObjectsExt = highlights?.filter(h => h.type === 'object_ext').map(h => h.id);

  const hlHouses = highlights?.filter(h => h.type === 'house').map(h => h.id);
  const hlHousesExt = highlights?.filter(h => h.type === 'house_ext').map(h => h.id);

  const hlSigns = highlights?.filter(h => h.type === 'sign').map(h => h.id);

  const horarSigns = props.mode === 'horar' ? calcHorarSigns(props.houses) : [];
  const soulCircles = () => Array.from({ length: 7 }, (v, k) => k).map(i =>
    <circle
      key={`soul-circle-${i}`}
      cx={props.x}
      cy={props.y}
      r={getDelimeterRadius(i)}
      stroke="var(--circle-border)"
      fill="transparent"
    />
  );

  const staticCircles = useMemo(() => <>
    {/* External objects background */}
    {hasExt &&
        <circle
          cx={props.x}
          cy={props.y}
          r={radiusModified.external}
          fill="var(--workspace-background)"
          stroke="var(--circle-border)"
        />
    }

    {/* External circle */}
    <circle
      cx={props.x}
      cy={props.y}
      r={radiusModified.zodiacsExternal}
      stroke={personalization.circle.zodiacs.style === 0 ? 'var(--circle-zodiacs-border)' : 'none'}
      fill={personalization.circle.zodiacs.style === 0 ? 'var(--circle-zodiacs-background)' : 'none'}
    />

    {/* Objects background */}
    <circle
      cx={props.x}
      cy={props.y}
      r={radiusModified.zodiacsInternal}
      fill="var(--workspace-background)"
    />

    {props.mode === 'soul' && soulCircles()}

    {/* Internal circle */}
    <circle
      cx={props.x}
      cy={props.y}
      r={getInternalRadius(props.mode)}
      stroke="var(--circle-border)"
      fill="var(--circle-internal-background)"
    />
  </>, [props.x, props.y, props.mode, hasExt, radiusModified, personalization]);

  return (
    <Container>
      {staticCircles}

      <Houses
        {...partProps}
        houses={houses}
        highlights={hlHouses}
        fixedStars={props.fixedStars}
      />

      {isNotTransits(props.mode) && props.mode !== 'prog_prog' &&
        <Houses
          {...partProps}
          isExt={true}
          houses={props.housesExt}
          highlights={hlHousesExt}
        />
      }

      <Zodiacs
        {...partProps}
        highlights={hlSigns}
        horarSigns={horarSigns}
        direction={rotationDir}
      />

      {(props.mode !== 'horar' || settings.ui.instruments.cardSettingsForms.horar.showAspects) && <Aspects
        {...partProps}
        aspects={props.aspects}
        radius={props.mode === 'horar' ? getInternalRadius(props.mode) : partProps.radius}
        strongs={props.strongs}
        highlights={hlAspects}
        objects={(props.mode === 'horar' ? props.objects : props.objectsExt) ?? []}
        settings={settings}
        houses={houses}
        horarSigns={horarSigns}
      />}

      {props.mode === 'soul' && <SoulObjects
        {...partProps}
        soulObjects={props.soulObjects}
      />}

      {hasExt &&
        <Objects
          {...partProps}
          isExt={true}
          objects={props.objectsExt}
          houses={[]}
          strongs={props.strongs}
          fixedStars={props.fixedStars}
          highlights={hlObjectsExt}
          horarSigns={horarSigns}
          showMinorPlanets={props.mode !== 'soul' && showMinorPlanets}
          t={t}
        />
      }

      <Objects
        {...partProps}
        objects={objects}
        houses={houses}
        strongs={props.strongs}
        fixedStars={props.fixedStars}
        highlights={hlObjects}
        horarSigns={horarSigns}
        soulStrongs={props.soulStrongs}
        chains={props.chains}
        showHigherPlanets={((props.mode === 'horar') && showHigherPlanets)}
        showMinorPlanets={props.mode !== 'soul' && showMinorPlanets}
        t={t}
      />
      <FixedStars
        {...partProps}
        fixedStars={props.showFixedStars ? props.fixedStars : []}
        objects={objects}
        houses={houses}
        highlights={hlSigns}
        horarSigns={horarSigns}
        soulStrongs={props.soulStrongs}
        t={t}
      />

      {/* {aspects
        .filter(a => a.type === AspectType.Conjunction)
        .map(a => {
          const pA = radialPoint(planetsInnerRadius, rotation + a.lon1);
          const pB = radialPoint(planetsInnerRadius, rotation + a.lon2);
          return (
            <path
              key={`arc_${a.obj1}_${a.obj2}`}
              d={`M${pA.x} ${pA.y} A ${planetsInnerRadius} ${planetsInnerRadius}, 0, 0, 1, ${pB.x} ${pB.y}`}
              stroke="purple"
              fill="transparent"
              strokeWidth={1.5}
            />
          );
        })
      } */}
    </Container>
  );
};

export const Container = styled.g`
  /* & * {
    transition: all 0.25s ease-in-out;
  } */
`;

export const Group = styled.g`
  cursor: pointer;
`;

// export default React.memo((props: CircleProps) => {
//   const onHighlighted = (what: string, id: number) => {
//     const _state = {
//       ...state,
//       aspectId: -1,
//       objectId: -1,
//       objectExtId: -1,
//     };

//     if (what === "aspect") {
//       _state.aspectId = id;
//     } else if (what === "object") {
//       _state.objectId = id;
//     } else if (what === "object_ext") {
//       _state.objectExtId = id;
//     }

//     setState(_state);

//     props.onHighlighted(
//       "natal", //props.mode,
//       what, id
//     );
//   }

//   const pointedAspect = aspects.find(a => a.id === state.aspectId);

// {pointedAspect?.diff && props.mode !== "solars" && (
//   <PointedAspect
//     aspect={pointedAspect}
//     ctx={ctx}
//     mode={props.mode}
//     progMode={props.progMode}
//     size={ctx.radius(0.045)}
//   />
// )}

// {state.objectId !== -1 && (
//   <PointedObject
//     id={state.objectId}
//     objects={objects}
//     ctx={ctx}
//     isExt={false}
//     mode={props.mode}
//     progMode={props.progMode}
//     size={ctx.radius(true ? 0.072 : 0.065)} // isAndreev
//     isQuad={props.isQuad}
//   />
// )}

// {state.objectExtId !== -1 && (
//   <PointedObject
//     id={state.objectExtId}
//     objects={objectsExt}
//     ctx={ctx}
//     isExt={true}
//     mode={props.mode}
//     progMode={props.progMode}
//     size={ctx.radius(true ? 0.072 : 0.065)} // isAndreev
//     isQuad={props.isQuad}
//   />
// )}
