import React, {useState} from 'react';
import styled from 'styled-components';

//import ym from 'react-yandex-metrika';

import { Button, Checkbox, IGenInfo } from 'src/libs';
import Counter from './Counter';
import { useTranslation } from 'src/i18n/useTranslation';

interface IPaymentCardMethod {
    country: string;
    title: string;
}

const paymentMethods = [{
    country: 'ru',
    title: "chronos.app.payments.paymentMethod.ru.title",
}, {
    country: 'foreign',
    title: "chronos.app.payments.paymentMethod.foreign.title",
}]

export default function Payment(props: {
  info: IGenInfo;
  onSuccess(): void;
}) {
  const [value, setValue] = React.useState(0);
  const [rules, setRules] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState<IPaymentCardMethod>(paymentMethods[0])
  const { t } = useTranslation();
  const onSubmit = async () => {
    //ym('reachGoal', 'click-payment-add-buildings-button');

    /*const res = await api.payment({
      type: 'additional',
      counter: props.info.prices[value][0],
      country: paymentMethod.country,
    }, 'charge');

    if (res) { props.onSuccess() }*/
    alert('FIXME: payments');
  };

  const totalSum = props.info.prices[value][1];

  const infoString = t("chronos.app.payments.infoString");

  return (
    <Container>
      <Info>{infoString}</Info>

      <Counters>
        <Counter
          title={t("chronos.app.payments.numberOfBuilds")}
          index={value}
          onChange={setValue}
          info={props.info}
        />
      </Counters>

      {/*<PaymentCardPicker items={paymentMethods} value={paymentMethod} onChange={(value: IPaymentCardMethod) => setPaymentMethod(value)} t={t}/>*/}

      <Totals>
        {totalSum > 0 && props.info.discount > 0 && <DiscountedSum><s>{totalSum} {t("chronos.app.payments.currensy")}</s></DiscountedSum>}
        <Total>{Math.round(totalSum * (1 - (props.info.discount || 0)))} {t("chronos.app.payments.currensy")}</Total>
        {props.info.discount > 0 && <Discount> -{props.info.discount * 100}%</Discount>}
      </Totals>

      <Button
        disabled={!rules || !totalSum}
        onClick={onSubmit}
      >{t("chronos.app.payments.pay")}</Button>

      <RulesCheck>
        <Checkbox
          label=""
          checked={rules}
          onChange={setRules}
        />
        {t("chronos.app.payments.agree")}&nbsp;
        <LinkDoc target="_blank" href="https://auth-dev.chronos.mg/#/terms">
          {t("chronos.app.payments.termsOfUse")}
        </LinkDoc>
      </RulesCheck>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

const Info = styled.div` 
  margin-top: 2em;
  color: var(--text-secondary);
  font-size: 0.875em;
`;

const Counters = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2em;
`;

const Totals = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 1em;
`;

const Total = styled.div`
  font-size: 1.75em;
`;

const DiscountedSum = styled(Total)`
  color: gray;
  margin-right: 0.75em;
`;

const Discount = styled.div`
  margin-left: 1em;
  color: white;
  font-size: 0.8em;
  background-color: #4093F4;
  padding: 0.5em;
  border-radius: 0.25em;
`;

const RulesCheck = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.75em;
  color: var(--text-secondary);
  margin-top: 2em;

  & :first-child {
    margin-right: 0.5em;
  }
`;

const LinkDoc = styled.a`
  text-decoration: underline;
`;
