import React from 'react';
import styled, { css } from 'styled-components';

import { AspectsPatterns, IMap, JonesPatterns, astroObjects, signs } from 'src/libs';

import { IWidgetData } from '../data';
import { SynthesisSigns } from './SynthesisSigns';

import Svg from 'src/ui/Svg';

import { InfoIcon } from 'src/assets/icons/system/index';
import { useTranslation } from 'src/i18n/useTranslation';
import FixedStarsView from '../FixedStarsView';

export default function Analyze(props: {
  data: IWidgetData;
  openedWidget: boolean;
  onChanged(key: string, value: any): void;
}) {
  const natal = props.data.maps.find(m => m.mode == 'natal' || m.mode === 'syn_natal') as IMap;
  const { t } = useTranslation();
  // This cannot happen because this widget should not be visible without a natal map
  if (!natal) { return null }

  const usedObjects = natal.objects.filter((item, i) => i < 10);

  const usedAspects = natal.aspects.filter(item => item.obj1 < 10 && item.obj2 < 10);
  const aspectsPatterns_ = new AspectsPatterns(usedAspects);
  const acpectsPatterns = aspectsPatterns_.findPatterns();

  const jonesPatterns = new JonesPatterns(usedObjects, natal.houses, natal.aspects, acpectsPatterns);
  const jonesPattern = jonesPatterns.getPattern();

  const synthesisSigns_ = new SynthesisSigns(usedObjects, natal.houses);
  const synthesisCrosses = synthesisSigns_.getCrossesWithObjects();

  //  .sort((a: any, b: any) => a.objectsCount < b.objectsCount ? 1 : -1);
  const synthesisSigns = synthesisSigns_.getSigns();

  const higlightJonesPattern = () => {
    props.onChanged(
      'highlights',
      usedObjects.map((item, index) => ({
        id: index,
        type: 'object',
        map: 'natal'
      }))
    );
  };

  const highlightJonesOption = (option: any) => {
    props.onChanged(
      'highlights',
      option.objects.map((item: any) => ({
        id: item.id,
        type: 'object',
        map: 'natal'
      }))
    );
  };

  const highlightAspectsPattern = (pattern: any) => {
    props.onChanged(
      'highlights',
      [
        ...pattern.points.map((item: number) => ({
          id: item,
          type: 'object',
          map: 'natal'
        })),
        ...pattern.aspects.map((item: any) => ({
          id: item.id,
          type: 'aspect',
          map: 'natal'
        }))
      ]
    );
  };

  const highlightElement = (element: any) => {
    props.onChanged(
      'highlights',
      element.objects.map((item: any) => ({
        id: item.id,
        type: 'object',
        map: 'natal'
      }))
    );
  };

  const highlightSign = (sign: number) => {
    props.onChanged(
      'highlights',
      [
        {
          id: sign,
          type: 'sign',
          map: 'natal'
        }
      ]
    );
  };

  const removeHighlight = () => {
    props.onChanged(
      'highlights',
      []
    );
  };

  return (
    <Container>
      {jonesPattern && <Group>
        <header>{t("chronos.app.instruments.widgets.analyze.figureJones")} <TW text={t("chronos.app.instruments.widgets.analyze.jones.description")}><InfoIcon /></TW></header>
        <Buttons>
          <Button active onMouseOver={higlightJonesPattern} onMouseOut={removeHighlight}>{t(jonesPattern.name)}</Button>
          {jonesPattern.options.map((item: any, i: number) =>
            <Button key={i} onMouseOver={highlightJonesOption.bind(null, item)} onMouseOut={removeHighlight}>
              {t(item.name)}
              {item.objects.map((object: any, iconI: number) => <Svg key={iconI} className={astroObjects[object.id].en} path={astroObjects[object.id].icon} />)}
            </Button>
          )}
        </Buttons>
      </Group>}

      {acpectsPatterns.length > 0 && <Group>
        <header>{t("base.configurations")} <TW text={t("chronos.app.instruments.widgets.analyze.jones.description.aspectsConfigurations")}><InfoIcon /></TW></header>
        <Buttons>
          {acpectsPatterns.map((item: any, i: number) => <Button key={i} onMouseOver={highlightAspectsPattern.bind(null, item)} onMouseOut={removeHighlight}>
            {t(item.name)}
            {item.points.map((point: number, iconI: number) => <Svg key={iconI} className={astroObjects[point].en} path={astroObjects[point].icon} />)}
          </Button>)}
        </Buttons>
      </Group>}

      <Group>
        <header>{t("chronos.app.instruments.widgets.analyze.signSintez")} <TW text={t("chronos.app.instruments.widgets.analyze.syntesis.description")}><InfoIcon /></TW></header>
        <Crosses>
          {synthesisCrosses.map((item: any, i: number) =>
            <Cross key={i}>
              <header>{item.name} <span>{item.objectsCount}</span></header>
              <Elements>
                {item.elements.map((element: any, i: number) =>
                  <Element key={i} color={element.color} onMouseOver={highlightElement.bind(null, element)} onMouseOut={removeHighlight}>
                    {element.objects.map((object: any, iconI: number) => <Svg key={iconI} className={astroObjects[object.id].en} path={astroObjects[object.id].icon} />)}
                  </Element>
                )}
              </Elements>
            </Cross>
          )}
        </Crosses>
      </Group>

      <Signs>
        <header>{t("chronos.app.instruments.widgets.analyze.sintez")}</header>

        {synthesisSigns.map((signIndex: number) =>
          <Sign key={signIndex} onMouseOver={highlightSign.bind(null, signIndex)} onMouseOut={removeHighlight} style={{
            color: `var(--circle-zodiacs-elements-${signs[signIndex].element})`
          }}>
            <Svg className={signs[signIndex].en} path={signs[signIndex].icon} viewBox="-64 -64 128 128" /> {t(signs[signIndex].ru)}
          </Sign>
        )}
      </Signs>
      
      <StyledFixedStarsView
        title={t("chronos.app.instruments.widgets.horar.analysis.stars")}
        houses={props.data.maps[0].houses}
        objects={props.data.maps[0].objects}
        fixedStars={props.data.maps[0].fixedStars || []}
        // @ts-ignore
        show={props.data.maps[0].showFixedStars}
        soulStrongs={props.data.maps[0].strongs || []}
        mode={props.data.maps[0].mode}
      />

    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 100%;
`;

const Hr = styled.div`
  height: 0px;
  margin-top: 0.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid var(--element-neutral);
`;

const Group = styled.div`
  margin-bottom: 1.125rem;
  font-size: 1rem;
  color: var(--text-primary);

  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 0.875rem;

    & svg {
      display: block;
      margin-left: 0.325rem;
      color: var(--icon-third);
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const Button = styled('button') <{ active?: boolean }>`
  min-height: 2.1rem; 
  display: inline-flex;
  align-items: center;
  gap: 0.250rem;

  padding: 0.3rem 0.75rem;
  color: var(--text-primary);
  border-radius: 5px;
  background: var(--element-neutral);
  border: 1px solid var(--input-border);
  cursor: pointer;
  outline: none;

  &:hover {
    background: var(--element-neutral-hover);
  }

  ${props => props.active && css`
    color: white;
    background: var(--color-blue) !important;
  `}

  font-size: 0.875rem;

& > svg {
  width: 1.4rem;
  
}

& > span {
  color: var(--text-secondary);
}
`;

const Crosses = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5rem;
`;

const Cross = styled.div`
  margin-left: 0.5rem;

  &:first-child {
    margin-left: 0;
  }

  & header {
    font-size: 0.875rem;
    margin-bottom: 0.8rem;

    & span {
      color: var(--text-secondary);
      margin-left: 0.25rem;
    }
  }
`;

const Elements = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Element = styled('div') <{ color: string }>`
  display: flex;
  gap: 0.4rem;
  padding: 0.4rem;
  border-radius: 34px;
  background: ${props => props.color};
  cursor: pointer;
  margin-left: 0.5rem;
  color: white;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    opacity: 0.6;
  }

  & svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const Signs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  font-size: 0.875rem;
  color: var(--text-secondary);

  & header {
    color: var(--text-primary);
  }
`;

const Sign = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.7rem;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  & > svg {
    width: 1rem;
    margin-right: 0.4rem;
  }
`;


const TW = styled.span<{text?: string}>``

const StyledFixedStarsView = styled(FixedStarsView)`
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
`;