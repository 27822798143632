import React, { useCallback, useState } from 'react';
import { getProfile } from 'src/store/localStorage/profile';

export function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState<(boolean | null)>(null);
  const [isTablet, setTablet] = React.useState<(boolean | null)>(null);

  React.useEffect(() => {
    const userAgent =
      (typeof globalThis.navigator === 'undefined') ? '' : globalThis.navigator?.userAgent;
    const mobile = Boolean(
      /android|webos|iphone|ipod|blackberry|bb|playbook|iemobile|windows phone|kindle|silk|opera mini|wpdesktop/i.exec(userAgent)
    );
    const tablet = Boolean(
      /Tablet|iPad|Nexus 7|Nexus 10|SM-T\d+|KFAPWI/i.exec(userAgent)
    )
    setMobile(mobile);
    setTablet(tablet);
  }, []);

  return { isMobile, isTablet };
}

const getCurrencySymbol = (code?: string) => {
  switch (code?.toLowerCase()) {
    case 'rub':
      return '₽';
    case 'usd':
      return '$';
    case 'kzt':
      return '₸';
    default:
      return '';
  }
}

export function useCurrency() {
  const lsUser = getProfile();
  const { currencyCode, country, region } = lsUser.region;

  return {
    currencyCode,
    symbol: getCurrencySymbol(currencyCode),
    country,
		region,
  }
}

export function useSwitcherHook(initial: boolean) {
  const [value, setValue] = useState(initial);

  return {
    value,
    on: useCallback(() => setValue(true), [value]),
    off: useCallback(() => setValue(false), [value]),
    switch: useCallback(() => {
      setValue(!value);
      return !value;
    }, [value])
  };
}
