
import React, { useState } from 'react';
import styled from 'styled-components';

import { AstroprocessorIcon } from 'src/assets/icons/system/index';

import { IAstroSettings, Radio, Input, Button, RangeInput as Range, Switcher, HousesSystem, IAstroHousesSystems, IMapsSettings, IMapStartingPoint, IMapRotationDir, IFixedStarsData } from 'src/libs';

import { TabWithIcons } from 'src/ui/SwipeTabs';

import LineHorizontal from 'src/ui/LineHorizontal';

import AspectsTableSwitcher from './components/map-settings/AspectsTableSwitcher';
import { useSelector } from 'src/store/utils';
import { allSettings, allSettingsAstro, getActiveAstroProfile } from 'src/store/reducers/settings/selectors';
import { getProfile } from 'src/store/localStorage/profile';
import _, { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { getSettings, getSettingsAstro, updateAstroSettings, updateSettings } from 'src/store/actions/settings';
import { useTranslation } from 'src/i18n/useTranslation';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import StarCatalog from './StarCatalog';
import StarData from 'src/app/pages/settings/components/stars.json';
import dayjs from 'dayjs';
import { createPortal } from 'react-dom';
export const housesSystemNames = [
  {
    label: "chronos.app.components.editForm.placidus",
    value: HousesSystem.Placidus
  },
  {
    label: "chronos.app.components.editForm.regiomontanus",
    value: HousesSystem.Regiomontanus
  },
  {
    label: "chronos.app.components.editForm.koh",
    value: HousesSystem.Koch
  },
  {
    label: "chronos.app.components.editForm.equal",
    value: HousesSystem.Equal
  }
];

export default function MapSettings() {
  const dispatch = useDispatch();

  const { data: profiles } = useSelector(allSettingsAstro);
  const { data: settings } = useSelector(allSettings);
  const activeAstroProfile = useSelector(getActiveAstroProfile);


  const [mode, setMode] = React.useState<string>('natal');
  const [maps, setMaps] = React.useState<IMapsSettings | null>(null);
  const [orbiseCorrector, setOrbiseCorrector] = React.useState(0);
  const [overSign0, setOverSign0] = React.useState(0);
  const [overSign, setOverSign] = React.useState(0);
  const [northernLatitude, setNorthernLatitude] = React.useState('');
  const [housesSystem, setHousesSystem] = React.useState<IAstroHousesSystems | null>(null);
  const [startingPoint, setStartingPoint] = React.useState<IMapStartingPoint>('asc');
  const [rotationDir, setRotationDir] = React.useState<IMapRotationDir>('ccw');
  const [showHigherPlanets, setShowHigherPlanets] = React.useState(activeAstroProfile.showHigherPlanets);
  const [showMinorPlanets, setShowMinorPlanets] = React.useState<boolean>(false);
  const [hideTransitAspects, setHideTransitAspects] = React.useState<boolean>(false);
  const [showStarCatalog, setShowStarCatalog] = useState(false);
  const [fixedStars, setFixedStars] = React.useState<{ [key: string]: IFixedStarsData }>({ ...activeAstroProfile.fixedStars });
  const { t } = useTranslation();

  const onChangeShowHigherPlanets = async (val: boolean) => {
    setShowHigherPlanets(val);
    await update({ showHigherPlanets: val }, true);
  }

  const onChangeShowMinorPlanets = async (val: boolean) => {
    setShowMinorPlanets(val);
    await update({ showMinorPlanets: val }, true);
  }

  const onChangeHideTransitAspects = async (val: boolean) => {
    setHideTransitAspects(val);
    await update({ hideTransitAspects: val }, true);
  }

  const reset = ({ maps, overSign0, overSign, housesSystem, northernLatitude, isRounded, startingPoint, rotationDir, fixedStars, showHigherPlanets, showMinorPlanets, hideTransitAspects }: IAstroSettings) => {
    //@ts-ignore;
    setMaps(maps);
    setOrbiseCorrector((maps as any)[mode].orbiseCorrector);
    setOverSign0(overSign0);
    setOverSign(overSign);
    setNorthernLatitude(changeDecimalToDegrees(northernLatitude).toString());
    setHousesSystem(housesSystem);
    setStartingPoint(startingPoint || 'asc');
    setRotationDir(rotationDir || 'ccw');
    setFixedStars(fixedStars);
    setShowHigherPlanets(showHigherPlanets);
    setShowMinorPlanets(showMinorPlanets);
    setHideTransitAspects(hideTransitAspects);
    //setIsRounded(isRounded);
  };

  const changeMode = (mode: string) => {
    if (!maps) { return }
    setOrbiseCorrector((maps as any)[mode].orbiseCorrector);
    setMode(mode);
  };

  React.useEffect(() => {
    const profile = profiles.find((item: any) => item.id === settings.activeProfileId);
    profile && reset(profile);
  }, [profiles, settings.activeProfileId]);

  const update = async (newValue: { [key: string]: any }, inRoot = false) => {
    if (!profiles) { return }

    const activeAstroProfile = profiles.find((item: any) => item.id === settings.activeProfileId);
    let profile = _.cloneDeep(activeAstroProfile);

    if (inRoot) {
      profile = {
        ...profile,
        ...newValue
      };
    } else {
      profile = {
        ...profile,
        maps: {
          ...profile.maps,
          [mode]: {
            ...(profile.maps as any)[mode],
            ...newValue
          }
        }
      };
    }

    const userProfile = profiles.find((p: IAstroSettings) => p.mutable)!;

    if (profile.id !== userProfile.id) {
      profile.id = userProfile.id;
      profile.title = userProfile.title;
      profile.mutable = true;
    }

    dispatch(updateAstroSettings(profile, (data: IAstroSettings) => {
      dispatch(updateSettings({ activeProfileId: data.id, activeCustomizationId: data.idCustomization }));
    }));

  }

  const updateHousesSystem = (key: string, value: number) => {
    setHousesSystem(prev => {
      if (!prev) { return null }
      const housesSystem = {
        ...prev,
        [key]: value
      };
      update({ housesSystem }, true);
      return housesSystem;
    });
  };

  const checkValueForUpdate = (value: any) => {
    if (value.length > 3) {
      return /^\d*\.?[0-5][0-9]?$/.test(value)
    } else {
      return /^\d*\.?[0-5]?$/.test(value)
    }
  }

  const changeDegreesToDecimal = (degrees: number) => {
    if (degrees) {
      const hasNumberAfterPoint = /^\d+\.\d+$/.test(degrees.toString())
      if (hasNumberAfterPoint) {
        const splintedNumber = degrees.toString().split('.')
        const decimal = `${splintedNumber[0]}.${Math.round(+splintedNumber[1] * 100 / 60).toString()}`
        return +decimal
      } else {
        return degrees
      }
    } else {
      return 0
    }
  }

  const onFixedStars = async (mode: string, list: string[], showWithObjects: boolean) => {
    setFixedStars({
      ...fixedStars,
      [mode]: {
        list,
        showWithObjects
      }
    });

    update({
      fixedStars: {
        ...fixedStars,
        [mode]: {
          list,
          showWithObjects
        }
      }
    }, true)
  }

  const changeDecimalToDegrees = (decimal: number) => {
    if (decimal) {
      const hasNumberAfterPoint = /^\d+\.\d+$/.test(decimal.toString())
      if (hasNumberAfterPoint) {
        const splintedNumber = decimal.toString().split('.')
        const degrees = `${splintedNumber[0]}.${Math.round(+splintedNumber[1] * 60 / 100).toString()}`
        return +degrees
      } else {
        return decimal
      }
    } else {
      return 0
    }
  }

  const onBlurNLHandler = (value: any) => {
    if (!value) {
      update({ northernLatitude: '0' }, true)
      return
    }

    const pointAtFirst = /^\./.test(value)
    const pointAtLast = /\.$/.test(value)

    if (pointAtFirst) {
      const newValue = `0${value}`
      update({ northernLatitude: changeDegreesToDecimal(+newValue) }, true)
    } else if (pointAtLast) {
      const newValue = value.toString().slice(0, -1)
      update({ northernLatitude: changeDegreesToDecimal(+newValue) }, true)
    } else {
      update({ northernLatitude: changeDegreesToDecimal(value) }, true)
    }
  }

  const onChangeNLHandler = (value: any) => {
    if (!checkValueForUpdate(value)) return
    setNorthernLatitude(value);
  }

  const updateStartingPoint = (val: IMapStartingPoint) => {
    setStartingPoint(val);
    update({ startingPoint: val }, true);
  }

  const updateRotationDir = (val: IMapRotationDir) => {
    setRotationDir(val);
    update({ rotationDir: val }, true);
  }

  return <Container>
    <Title>{t("chronos.app.settings.astro.mapSettings")}</Title>
    <Text>{t("chronos.mobile.mapSettings.attachPhoneForAdditionalEntrance.1")}<br />{t("chronos.mobile.mapSettings.attachPhoneForAdditionalEntrance.2")}</Text>
    {<Buttons>
      <span><Button color={mode == 'natal' ? 'var(--bg-element-neutral)' : 'transparent'} onClick={() => {changeMode('natal')}}>{t("astro.natal")}</Button></span>
      <span><Button color={mode == 'synastry' ? 'var(--bg-element-neutral)' : 'transparent'} onClick={() => {changeMode('synastry')}}>{t("astro.synastry")}</Button></span>
      <span><Button color={mode == 'directions' ? 'var(--bg-element-neutral)' : 'transparent'} onClick={() => {changeMode('directions')}}>{t("astro.directions")}</Button></span>
      <span><Button color={mode == 'solars' ? 'var(--bg-element-neutral)' : 'transparent'} onClick={() => {changeMode('solars')}}>{t("astro.solars")}</Button></span>
      <span><Button color={mode == 'transits' ? 'var(--bg-element-neutral)' : 'transparent'} onClick={() => {changeMode('transits')}}>{t("astro.transits")}</Button></span>
      <span><Button color={mode == 'progressions' ? 'var(--bg-element-neutral)' : 'transparent'} onClick={() => {changeMode('progressions')}}>{t("astro.progressions")}</Button></span>
    </Buttons>}

    <MapContainer>
      {maps &&
      <AspectsTableSwitcher
        orbises={(maps as any)[mode].orbises}
        onChange={orbises => update({ orbises })}
      />
    }
    </MapContainer>

    <ObjectsCatalogGroup>
      <div className='group-title'>{t("chronos.app.settings.objectCatalog")}</div>
      <div className='group-subtitle'>{t("chronos.app.settings.map.objectsCatalogSubtitle")}</div>
      <span><Button color={'var(--bg-element-neutral)'} onClick={() => {setShowStarCatalog(true)}}>{t("chronos.profile.security.look")}</Button></span>
    </ObjectsCatalogGroup>

    <RadiosContainer>
      <Title>{t("chronos.app.settings.defaultHouseSystem")}</Title>
      <Radios>
        {housesSystemNames.map(item => <Radio
          key={item.value}
          onChange={v => updateHousesSystem('common', v)}
          value={item.value}
          label={t(item.label)}
          checked={(housesSystem?.common ?? HousesSystem.Placidus) === item.value}
        />)}
      </Radios>
    </RadiosContainer>
    <RangesContainer>
      <Title>{t("chronos.app.settings.defaultOrbCorrector")}</Title>
      <Ranges>
        <StyledRangeInput
          label={t("chronos.mobile.mapSettings.orbises")}
          value={orbiseCorrector}
          onChange={setOrbiseCorrector}
          onMouseUp={() => update({ orbiseCorrector })}
          min={-100}
          max={100}
          step={1}
          height={'3rem'}
        />
      </Ranges>
    </RangesContainer>

    {mode === 'natal' && <RangesContainer>
      <Title>{t("chronos.app.settings.throughSignificance")}</Title>
      <Ranges>
        <StyledRangeInput
          label={t("chronos.app.settings.connections")}
          value={overSign0}
          onChange={setOverSign0}
          onMouseUp={() => update({ overSign0 }, true)}
          min={-100}
          max={100}
          step={1}
          height={'3rem'}
        />

        <StyledRangeInput
          label={t("chronos.app.settings.astro.aspects")}
          value={overSign}
          onChange={setOverSign}
          onMouseUp={() => update({ overSign }, true)}
          min={-100}
          max={100}
          step={1}
          height={'3rem'}
        />
      </Ranges>
    </RangesContainer> }

    <LineHorizontalWrapper><LineHorizontal /></LineHorizontalWrapper>

    {/*<BigTitle>Общие настройки</BigTitle>
    <Text>Равным образом рамки и место обучения кадров влечет за собой </Text>

    <RadiosContainer>
      <Title>Система знака зодиака</Title>
      <Radios>
        <Radio onChange={() => { }} label="Тропический" checked={true} />
        <Radio onChange={() => { }} label="Сидерический" checked={false} />
      </Radios>
    </RadiosContainer>

    <RadiosContainer>
      <Title>Система координат</Title>
      <Radios>
        <Radio onChange={() => { }} label="Топоцентрика" checked={true} />
        <Radio onChange={() => { }} label="Геоцентрика" checked={false} />
      </Radios>
    </RadiosContainer>

    <LineHorizontalWrapper><LineHorizontal /></LineHorizontalWrapper>*/}

    <BigTitle>{t("chronos.mobile.mapSettings.northernMaps")}</BigTitle>
    <Text>{t("chronos.mobile.mapSettings.northernMaps.description")}</Text>

    <InputContainer>
      <Title>{t("chronos.app.settings.northernMapsFrom")}</Title>
      <Text>{t("chronos.app.settings.northernMapsFrom.subtitle")}</Text>
      <Input selectOnFocus={true} size='big' value={northernLatitude.toString()} onChange={v => onChangeNLHandler(v)} onBlur={() => onBlurNLHandler(northernLatitude)} />
    </InputContainer>

    <RadiosContainer>
      <Title>{t("chronos.mobile.mapSettings.northernMaps.houseSystemForNorthernMaps")}</Title>
      <Radios>
        {housesSystemNames.map(item => <Radio
          key={item.value}
          onChange={v => updateHousesSystem('northern', v)}
          value={item.value}
          label={t(item.label)}
          checked={(housesSystem?.northern ?? HousesSystem.Equal) === item.value}
        />)}
      </Radios>
    </RadiosContainer>

    <RadiosContainer>
      <Title>{t("chronos.app.settings.houseSystem.horarHouses")}</Title>
      <Radios>
        {housesSystemNames.map(item => <Radio
          key={item.value}
          onChange={v => updateHousesSystem('horar', v)}
          value={item.value}
          label={t(item.label)}
          checked={(housesSystem?.horar ?? HousesSystem.Placidus) === item.value}
        />)}
      </Radios>
    </RadiosContainer>

    <RadiosContainer>
      <Title>{t("chronos.app.settings.startingPoint")}</Title>
      <Radios>
        <Radio label='Asc' checked={startingPoint === 'asc'} onChange={() => { updateStartingPoint('asc') }} />
        <Radio label={t("chronos.app.settings.startFromAries")} checked={startingPoint === 'aries'} onChange={() => { updateStartingPoint('aries') }} />
      </Radios>

      <Radios>
        <Radio label={t("chronos.app.settings.rotationDirLeft")} checked={rotationDir === 'ccw'} onChange={() => { updateRotationDir('ccw') }} />
        <Radio label={t("chronos.app.settings.rotationDirRight")} checked={rotationDir === 'cw'} onChange={() => { updateRotationDir('cw') }} />
      </Radios>
    </RadiosContainer>
    
    {/* высшие планеты хорара */}
    <CheckboxContainer>   
      <div className='group-item-content'>
        <h2 className='group-item-title'>{t("chronos.app.settings.showHigherPlanets")}</h2>
        <h3 className="group-item-subtitle">{t("chronos.app.settings.showHigherPlanets.inHorarMap")}</h3>
      </div>
      <div className='group-item-value'>{showHigherPlanets ? t("chronos.app.ui.on") : t("chronos.app.ui.off")}</div>
      <div className="group-item-controls">
        <StyledSwitcher
          checked={showHigherPlanets}
          onChange={onChangeShowHigherPlanets}
        />
      </div>
    </CheckboxContainer>
    
    {/* Малые планеты */}
    <CheckboxContainer>
      <div className='group-item-content'>
        <h2 className='group-item-title'>{t("astro.eris")}</h2>
        <h3 className="group-item-subtitle">{t("chronos.app.settings.showAdditionalPlanets")}</h3>
      </div>
      <div className='group-item-value'>{showMinorPlanets ? t("chronos.app.ui.on") : t("chronos.app.ui.off")}</div>
      <div className="group-item-controls">
        <StyledSwitcher
          checked={showMinorPlanets}
          onChange={onChangeShowMinorPlanets}
        />
      </div>
    </CheckboxContainer>

    {/* Скрытие транзитных аспектов */}
    <CheckboxContainer>
      <div className='group-item-content'>
        <h2 className='group-item-title'>{t("chronos.app.settings.hidingTransitAspects")}</h2>
        <h3 className="group-item-subtitle">{t("chronos.app.settings.hidingTransitAspectsSubTitle")}</h3>
      </div>
      <div className='group-item-value'>{hideTransitAspects ? t("chronos.app.ui.on") : t("chronos.app.ui.off")}</div>
      <div className="group-item-controls">
        <StyledSwitcher
          checked={hideTransitAspects}
          onChange={onChangeHideTransitAspects}
        />
      </div>
    </CheckboxContainer>

    {
      showStarCatalog && 
        createPortal(
          <PopupBottom title={t("chronos.app.settings.objectCatalog")}
          open={showStarCatalog}
          onClose={() => setShowStarCatalog(false)}
        >
          <StarCatalog list={StarData}
            selected={fixedStars}
            dateTime={dayjs().toISOString()}
            outsideMode={mode}
            onSelect={onFixedStars}
            withModeSelector />
        </PopupBottom>,
        document.getElementById('appPopups')!
      )
    }
  </Container>
}

export function Tab ({text}: {text: string}) {
  const { t } = useTranslation();
  return <TabWithIcons>
    <AstroprocessorIcon />
      {t(text)}
</TabWithIcons>}

const Container = styled.div`
  margin-top: 1rem;
  width: calc(100% - 1px);
  white-space: initial;
`

const StyledSwitcher = styled(Switcher)`
  margin-right: 0;
`;

const CheckboxContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  
  .group-item {
    
    &.pointer {
      cursor: pointer;
    }

    &-content {
      display: flex;
      flex-grow: 2;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      margin: 0;
      font-size: 1.125rem;
      line-height: 1.3125rem;
      font-weight: 200;
    }

    &-subtitle {
      max-width: 90%;
      margin: 0;
      margin-top: 0.25rem;
      font-size: 0.813rem;
      line-height: 1;
      color: var(--text-secondary);
      font-weight: 200;
    }

    &-value {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      font-size: 1rem;
    }

    &-controls {
      position: relative;
      display: flex;
      justify-content: start;
      align-items: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;
        transform: rotate(180deg);
      }
    }
  }
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
`
const BigTitle = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
`
const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--text-secondary);
  margin-top: 0.375rem;
`


const Buttons = styled.div`
  margin-top: 1.5rem;
  width: 100%;

  > span {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
  }
`

const MapContainer = styled.div`
  margin-top: 0.5rem;
`

const RadiosContainer = styled.div`
  margin-top: 2rem;
`

const Radios = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.625rem;
  grid-row-gap: 1rem;
`

const RangesContainer = styled.div`
  margin-top: 1.75rem;
`
const StyledRangeInput = styled(Range)`

  & input[type="range"] {
    display: block;
    position: relative;
    width: calc(100% - 4px);
    margin: 0 auto;
    background-image: none;
    border-radius: 0.5rem;
    background-color: var(--bg-element-neutral);
    border: 1px solid var(--border-lines);
  }

  &::-webkit-slider-thumb {
    box-sizing: border-box;
    width: 2rem;
    border-radius: 0.4rem;
    background: none;
    background-color: var(--bg-element-neutral);
    /* border: 1px solid var(--border-lines); */
  }
  &::-moz-range-thumb {
    box-sizing: border-box;
    width: 2rem;
    border-radius: 0.4rem;
    background: none;
    background-color: var(--bg-element-neutral);
    /* border: 1px solid var(--border-lines); */
  }
`;

const Ranges = styled.div`
  margin-top: 12px;
  display: grid;
  grid-row-gap: 0.75rem;
`

const LineHorizontalWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const InputContainer = styled.div`
  margin-top: 1rem;

  input {
    margin-top: 0.75rem;
    width: 4rem;
  }
`

const ObjectsCatalogGroup = styled.div`
  margin-top: 2rem;

  .group-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }

  .group-subtitle {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  button {
    width: 100%;
    color: var(--text-primary);
  }
`
